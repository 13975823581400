var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationProvider',{attrs:{"name":"DAPP_PAGE_NAME_FIELD","rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"col-form-label",attrs:{"lg":"2","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t('DAPP_PAGE_NAME_FIELD')))])]),_c('CCol',{attrs:{"lg":"10","md":"10"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dappDetails.name),expression:"dappDetails.name"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.dappDetails.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dappDetails, "name", $event.target.value)}}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}])}),_c('ValidationProvider',{attrs:{"name":"DAPP_PAGE_ADDRESS_FIELD","rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"col-form-label",attrs:{"lg":"2","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t('DAPP_PAGE_ADDRESS_FIELD')))])]),_c('CCol',{attrs:{"lg":"10","md":"10"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dappDetails.address),expression:"dappDetails.address"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.dappDetails.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dappDetails, "address", $event.target.value)}}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}])}),_c('ValidationProvider',{attrs:{"name":"DAPP_PAGE_ICON_FIELD","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"col-form-label",attrs:{"lg":"2","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t('DAPP_PAGE_ICON_FIELD')))])]),_c('CCol',{attrs:{"lg":"10","md":"10"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dappDetails.icon),expression:"dappDetails.icon"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.dappDetails.icon)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dappDetails, "icon", $event.target.value)}}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}])}),_c('ValidationProvider',{attrs:{"name":"DAPP_PAGE_URL_FIELD","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"col-form-label",attrs:{"lg":"2","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t('DAPP_PAGE_URL_FIELD')))])]),_c('CCol',{attrs:{"lg":"10","md":"10"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dappDetails.url),expression:"dappDetails.url"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.dappDetails.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dappDetails, "url", $event.target.value)}}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}])}),_c('ValidationProvider',{attrs:{"name":"DAPP_PAGE_DESCRIPTION_FIELD","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"col-form-label",attrs:{"lg":"2","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t('DAPP_PAGE_DESCRIPTION_FIELD')))])]),_c('CCol',{attrs:{"lg":"10","md":"10"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.dappDetails.description),expression:"dappDetails.description"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.dappDetails.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dappDetails, "description", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}])}),_c('ValidationProvider',{attrs:{"name":"DAPP_PAGE_DEFAULT_FLG_FIELD"}},[_c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"col-form-label",attrs:{"lg":"2","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t('DAPP_PAGE_DEFAULT_FLG_FIELD')))])]),_c('CCol',{attrs:{"lg":"10","md":"10"}},[_c('div',{staticClass:"btn-switch-wrapper"},[_c('label',{staticClass:"btn-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dappDetails.is_default_flg),expression:"dappDetails.is_default_flg"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.dappDetails.is_default_flg)?_vm._i(_vm.dappDetails.is_default_flg,null)>-1:(_vm.dappDetails.is_default_flg)},on:{"change":function($event){var $$a=_vm.dappDetails.is_default_flg,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dappDetails, "is_default_flg", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dappDetails, "is_default_flg", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dappDetails, "is_default_flg", $$c)}}}}),_c('span',{staticClass:"check-silder"})])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <ValidationProvider name="DAPP_PAGE_NAME_FIELD" rules="required|max:128">
      <CRow class="form-group" slot-scope="{ errors }">
        <CCol lg="2" md="2" class="col-form-label">
          <label>{{ $t('DAPP_PAGE_NAME_FIELD') }}</label>
        </CCol>
        <CCol lg="10" md="10">
          <input type="text" v-model="dappDetails.name" class="form-control" />
          <span class="error-msg-wrap">
            <span class="error-msg">{{ errors[0] }}</span>
          </span>
        </CCol>
      </CRow>
    </ValidationProvider>

    <ValidationProvider name="DAPP_PAGE_ADDRESS_FIELD" rules="required|max:128">
      <CRow class="form-group" slot-scope="{ errors }">
        <CCol lg="2" md="2" class="col-form-label">
          <label>{{ $t('DAPP_PAGE_ADDRESS_FIELD') }}</label>
        </CCol>
        <CCol lg="10" md="10">
          <input type="text" v-model="dappDetails.address" class="form-control" />
          <span class="error-msg-wrap">
            <span class="error-msg">{{ errors[0] }}</span>
          </span>
        </CCol>
      </CRow>
    </ValidationProvider>

    <ValidationProvider name="DAPP_PAGE_ICON_FIELD" rules="max:256">
      <CRow class="form-group" slot-scope="{ errors }">
        <CCol lg="2" md="2" class="col-form-label">
          <label>{{ $t('DAPP_PAGE_ICON_FIELD') }}</label>
        </CCol>
        <CCol lg="10" md="10">
          <input type="text" v-model="dappDetails.icon" class="form-control" />
          <span class="error-msg-wrap">
            <span class="error-msg">{{ errors[0] }}</span>
          </span>
        </CCol>
      </CRow>
    </ValidationProvider>

    <ValidationProvider name="DAPP_PAGE_URL_FIELD" rules="max:256">
      <CRow class="form-group" slot-scope="{ errors }">
        <CCol lg="2" md="2" class="col-form-label">
          <label>{{ $t('DAPP_PAGE_URL_FIELD') }}</label>
        </CCol>
        <CCol lg="10" md="10">
          <input type="text" v-model="dappDetails.url" class="form-control" />
          <span class="error-msg-wrap">
            <span class="error-msg">{{ errors[0] }}</span>
          </span>
        </CCol>
      </CRow>
    </ValidationProvider>

    <ValidationProvider name="DAPP_PAGE_DESCRIPTION_FIELD" rules="max:1000">
      <CRow class="form-group" slot-scope="{ errors }">
        <CCol lg="2" md="2" class="col-form-label">
          <label>{{ $t('DAPP_PAGE_DESCRIPTION_FIELD') }}</label>
        </CCol>
        <CCol lg="10" md="10">
          <textarea type="text" v-model="dappDetails.description" class="form-control" />
          <span class="error-msg-wrap">
            <span class="error-msg">{{ errors[0] }}</span>
          </span>
        </CCol>
      </CRow>
    </ValidationProvider>

    <ValidationProvider name="DAPP_PAGE_DEFAULT_FLG_FIELD">
      <CRow class="form-group">
        <CCol lg="2" md="2" class="col-form-label">
          <label>{{ $t('DAPP_PAGE_DEFAULT_FLG_FIELD') }}</label>
        </CCol>
        <CCol lg="10" md="10">
          <div class="btn-switch-wrapper">
            <label class="btn-switch">
              <input type="checkbox" class="checkbox" v-model="dappDetails.is_default_flg" />
              <span class="check-silder"></span>
            </label>
          </div>
        </CCol>
      </CRow>
    </ValidationProvider>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'EditDAppComponent',
  components: {},
  data() {
    return {};
  },
  props: {
    dappDetails: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {},
  async mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.dapp-details-container {
  .card {
    .form-group {
      margin-bottom: 0.5rem;
      .col-form-label {
        width: 100%;
        text-align: right;
      }

      .btn-switch-wrapper {
        margin-top: 10px;
      }
    }

    .error-message {
      color: red;
    }
  }
}
</style>

<template>
  <div class="dapp-details-container wrapper">
    <CRow class>
      <CCol lg="9" md="9">
        <CCard>
          <CCardHeader>
            <router-link :to="dappListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('DAPP_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <VclList />
          </CCardBody>

          <CCardBody v-if="!isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, dirty }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <EditDAppComponent :dappDetails="dappDetails" />

                <div class="error-message" v-if="errorMessage">
                  <div>{{ errorMessage }}</div>
                </div>
                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton @click="cancel(dirty)" class="btn btn-secondary btn-cancel">{{ $t('DAPP_PAGE_CANCEL_BUTTON') }}</CButton>
                    <CButton @click="onSubmit()" v-if="!isEdit && isAllowedCreate" color="primary" class="btn btn-update btn-create">{{
                      $t('DAPP_PAGE_CREATE_BUTTON')
                    }}</CButton>
                    <CButton @click="onSubmit()" v-if="isEdit && isAllowedUpdating" color="primary" class="btn btn-update">{{
                      $t('DAPP_PAGE_UPDATE_BUTTON')
                    }}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>

        <StakingModal :linkTo="dappListUrl" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import StakingModal from '@/components/StakingModal';
import EditDAppComponent from '@/components/DApp/EditDAppComponent';
import { VclList } from 'vue-content-loading';

export default {
  name: 'EditDApp',
  components: {
    VclList,
    StakingModal,
    EditDAppComponent,
  },
  data() {
    return {
      dappId: null,
      isEdit: false,
      isLoading: true,
      isAllowedUpdating: false,
      isAllowedCreate: false,
      warningLeavePage: false,
      errorMessage: '',
      dappListUrl: '/admin/setting/dapp',
      dappDetails: {},
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    const dappId = this.$route.params.dappId;
    this.checkPermissions();

    if (dappId) {
      this.isEdit = true;
      this.dappId = dappId;
      await this.getDappDetails(this.dappId);
      this.isLoading = false;
    } else {
      this.isEdit = false;
      this.isLoading = false;
    }
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'UPDATE_DAPP') {
            this.isAllowedUpdating = true;
          }
          if (item.name === 'CREATE_DAPP') {
            this.isAllowedCreate = true;
          }
        });
      }
    },
    async getDappDetails(dappId) {
      try {
        const res = await this.$http.get(endpoints.getDappDetails(dappId));
        this.dappDetails = res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('DAPP_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    backToDappList() {
      this.$router.push(this.dappListUrl);
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.dappListUrl);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.errorMessage = '';
      if (this.isEdit) {
        return this.updateDapp();
      }
      return this.createDapp();
    },
    async updateDapp() {
      if (this.dappDetails.isProcessing) {
        return;
      }

      this.dappDetails.isProcessing = true;

      try {
        const data = this.getDataForm();
        const result = await this.$http.put(endpoints.updateDapp(this.dappId), data);

        this.dappDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('DAPP_PAGE_DELETE_NOTIFY_TITLE_SUCCESSFUL'),
          text: this.$t('DAPP_PAGE_UPDATE_SUCCESSFUL'),
        });
        this.backToDappList();
      } catch (err) {
        this.dappDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('DAPP_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    getDataForm() {
      return {
        name: this.dappDetails.name || '',
        address: this.dappDetails.address || '',
        icon: this.dappDetails.icon || '',
        url: this.dappDetails.url || '',
        description: this.dappDetails.description || '',
        is_default_flg: this.dappDetails.is_default_flg || false,
      };
    },
    async createDapp() {
      if (this.dappDetails.isProcessing) {
        return;
      }

      this.dappDetails.isProcessing = true;
      try {
        const data = this.getDataForm();
        const result = await this.$http.post(endpoints.createDapp, data);

        this.dappDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('DAPP_PAGE_DELETE_NOTIFY_TITLE_SUCCESSFUL'),
          text: this.$t('DAPP_PAGE_CREATE_SUCCESSFUL'),
        });

        this.backToDappList();
      } catch (err) {
        this.dappDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('DAPP_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.dapp-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }
    .tab-content {
      padding: 10px 10px 0 10px;
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 36px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }

    .error-message {
      color: red;
    }
  }
}
</style>
